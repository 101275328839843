<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="1000">
      <v-card :disabled="showLoading" :loading="showLoading">

        <v-card-title>
          {{ dialogTitle }}
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text>

          <v-alert v-if="Object.keys(errors).length"
                   border="left"
                   class="mt-5 ml-15 mr-15"
                   dense
                   dismissible
                   prominent
                   text
                   type="error">
            <p class="font-weight-bold"> Veuillez corriger les erreur suivants :</p>
            <ul v-for="(error,i) in errors" :key="i">
              <li>{{ error[0] }}</li>
            </ul>
          </v-alert>

          <v-tabs v-model="tab"
                  centered
                  class="py-2">

            <v-tab class="b-700">
              Information du compte
            </v-tab>

            <v-tab class="b-700">
              <v-badge :content="form.salepoint_account_ids.length"
                       color="blue"
              >
                Affectation POS
              </v-badge>

            </v-tab>

            <v-tab class="b-700">
              <v-badge :content="form.wholesaler_ids.length"
                       color="blue"
              >
                Affectation Grossistes
              </v-badge>

            </v-tab>

          </v-tabs>

          <v-tabs-items v-model="tab">

            <v-tab-item class="pt-4 pl-16 pr-16 pb-4">

              <v-text-field v-model.trim="form.name"
                            :error-messages="errors.name"
                            label="Nom complet *"/>

              <v-file-input ref="photo"
                            :clearable="false"
                            :error-messages="errors.photo"
                            accept="image/png,image/jpeg"
                            append-icon="mdi-image-outline"
                            label="Photo"
                            show-size
                            small-chips
                            @change="uploadFile"/>

              <v-text-field v-model.trim="form.email"
                            :error-messages="errors.email"
                            label="Email *"
                            type="email"/>

              <v-text-field v-model.trim="form.phone"
                            :error-messages="errors.phone"
                            label="Téléphone *"/>

              <v-text-field v-model.trim="form.password"
                            :error-messages="errors.password"
                            label="Mot de passe *"/>

              <v-switch v-model="form.is_active"
                        :error-messages="errors.is_active"
                        false-value="false"
                        label="Active/Inactive"
                        true-value="true"/>
            </v-tab-item>

            <v-tab-item class="pa-4">
              <SalepointsList ref="salepointsList" :form.sync="form"/>
            </v-tab-item>

            <v-tab-item class="pa-4">
              <WholesalersList ref="wholesalersList" :form.sync="form"/>
            </v-tab-item>

          </v-tabs-items>

        </v-card-text>

        <v-divider/>

        <v-card-actions>

          <v-spacer/>

          <v-btn :disabled="btnLoading"
                 :loading="btnLoading"
                 color="primary"
                 depressed
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>

        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import SalepointsList from "@/views/supervisors/components/form/SalepointsList.vue";
import WholesalersList from "@/views/supervisors/components/form/WholesalersList.vue";

export default {
  components: {SalepointsList, WholesalersList},
  data() {
    return {
      tab: null,
      btnLoading: false,
      showLoading: false,
      dialogTitle: null,
      dialog: false,
      form: {
        id: '',
        name: '',
        photo: '',
        email: '',
        phone: '',
        password: '',
        is_active: 'true',
        salepoint_account_ids: [],
        wholesaler_ids: [],
      },
      errors: {},
    }
  },
  methods: {
    open(item) {
      this.tab = null
      this.dialog = true
      this.$refs.photo ? this.$refs.photo.reset() : null
      this.dialogTitle = item ? "Modifier un compte ASM" : "Ajouter un compte ASM"
      if (item) {
        this.show(item.id)
      } else {
        this.form = {
          id: '',
          name: '',
          photo: '',
          email: '',
          phone: '',
          password: '',
          is_active: 'true',
          salepoint_account_ids: [],
          wholesaler_ids: [],
        }
      }
    },
    close() {
      this.errors = {}
      this.dialog = false
    },
    save() {
      this.btnLoading = true
      this.errors = {}
      this.$Progress.start()
      const url = this.form.id ? '/supervisors/update' : '/supervisors/store'

      let formData = new FormData()

      formData.append('id', this.form.id)
      formData.append('name', this.form.name)
      formData.append('photo', this.form.photo)
      formData.append('email', this.form.email)
      formData.append('phone', this.form.phone)
      formData.append('password', this.form.password)
      formData.append('is_active', this.form.is_active)
      formData.append('salepoint_account_ids', this.form.salepoint_account_ids)
      formData.append('wholesaler_ids', this.form.wholesaler_ids)

      HTTP.post(url, formData).then(() => {
        this.$successMessage = 'Ce superviseur a été enregistré avec succès'
        this.btnLoading = false
        this.$Progress.finish()
        this.$emit('refresh')
        this.close()
      }).catch(err => {
        this.btnLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        console.log(err)
      })
    },
    uploadFile(file) {
      this.form.photo = file
    },
    show(id) {
      this.showLoading = true
      HTTP.get('/supervisors/' + id + '/show').then((res) => {
        this.showLoading = false
        let item = res.data.data
        this.form = {
          id: item.id,
          name: item.name,
          photo: '',
          email: item.email,
          phone: item.phone,
          password: '',
          is_active: item.is_active ? 'true' : 'false',
          salepoint_account_ids: item.salepoint_account_ids,
          wholesaler_ids: item.wholesaler_ids,
        }
      }).catch(err => {
        this.showLoading = false
        console.log(err)
      })
    },
  },
  watch: {
    tab(val) {
      if (val === 1) {
        let _vm = this
        setTimeout(function () {
          _vm.$refs.salepointsList.getSalepoints(_vm.form.salepoint_account_ids)
        }, 500)
      }
      if (val === 2) {
        let _vm = this
        setTimeout(function () {
          _vm.$refs.wholesalersList.getWholesalers(_vm.form.wholesaler_ids)
        }, 500)
      }
    }
  },
}
</script>

<style scoped>

</style>