<template>
  <div>
    <v-tabs centered
            color="blue"
            hide-slider>

      <v-tab active-class="blue white--text" class="b-700 justify-start">
        <v-icon left>
          mdi-filter-outline
        </v-icon>
        Filtrage simple
      </v-tab>

      <v-tab active-class="blue white--text" class="b-700 justify-start">
        <v-icon left>
          mdi-filter-outline
        </v-icon>
        Filtrer par fichier excel
      </v-tab>

      <v-tab-item class="my-5">
        <SPFilterComponent ref="SPFilterComponent"
                           @set-salepoint-ids="[filter_salepoint_ids = $event,pagination.current = 1 ,getSalepoints()]"/>
      </v-tab-item>

      <v-tab-item class="my-5">
        <SPExcelFilterComponent ref="SPExcelFilterComponent"
                                @set-salepoint-ids="[filter_salepoint_ids = $event,pagination.current = 1 ,getSalepoints()]"/>
      </v-tab-item>

    </v-tabs>


    <div>

      <div
          class="d-flex justify-space-between align-center align-content-center pa-4 mb-1 font-weight-bold bg-blue-lighten">
        <span class="blue--text">Total POS : {{ total }}</span>
        <v-spacer/>
        <span class="blue--text">
                                   <v-icon class="mt--4">mdi-check-circle-outline</v-icon>
                                    Total POS Sélectionnés : {{ form.salepoint_account_ids.length }}
                                   </span>
      </div>


      <v-card-actions>
        <div class="d-flex align-center">
          <v-select v-model="per_page"
                    :items="[10,20,50,100,200,300,400,500]"
                    :style="{width: '120px'}"
                    dense
                    hide-details
                    label="Ligne par page"
                    outlined></v-select>

          <v-btn class="ml-2" depressed
                 @click="[filter_salepoint_ids = [],pagination.current = 1 ,getSalepoints()]">
            <v-icon left>mdi-table-refresh</v-icon>
            Réinitialiser
          </v-btn>
        </div>
        <v-spacer/>
        <v-pagination v-if="total>per_page"
                      v-model="pagination.current"
                      :length="pagination.total"
                      circle
                      total-visible="6"
                      @input="onPageChange"
        ></v-pagination>
      </v-card-actions>

      <v-data-table v-model="selected"
                    :headers="headers"
                    :items="salepoints"
                    :loading="loading"
                    checkbox-color="success"
                    class="table-border"
                    disable-pagination
                    hide-default-footer
                    item-key="id"
                    show-select
                    @item-selected="selectRow"
                    @toggle-select-all="selectAllRow">

        <template v-slot:item.photo="{ item }">
          <v-avatar size="38">
            <v-img v-if="item.salepoint_account && item.salepoint_account.photo"
                   :src="FILE_URL + item.salepoint_account.photo"></v-img>
            <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
          </v-avatar>
        </template>

        <template v-slot:item.code="{ item }">
          <span class="font-weight-medium blue--text">
            {{ item.code }}
          </span>
        </template>


        <template v-slot:item.app_phone="{ item }">
                                   <span v-if="item.salepoint_account && item.salepoint_account.salepoint_phone"
                                         class="info--text">
                                  {{ item.salepoint_account.salepoint_phone }}
                                    </span>
          <span v-else>-</span>
        </template>

        <template v-slot:item.points="{ item }">
          <v-chip v-if="item.salepoint_account" color="blue" dark small>
            {{ item.salepoint_account.points | toCurrency }}
          </v-chip>
          <v-chip v-else color="blue" dark disabled small>
            0.00
          </v-chip>
        </template>

        <template v-slot:item.gifty_id="{ item }">

          <v-icon v-if="item.salepoint_account && item.salepoint_account.gifty_id"
                  color="success">
            mdi-check-circle
          </v-icon>

          <v-icon v-else color="red">mdi-close-circle</v-icon>
        </template>

      </v-data-table>

      <v-card-actions>
        <div>
          <v-select v-model="per_page"
                    :items="[10,20,50,100,200,300,400,500]"
                    :style="{width: '120px'}"
                    dense
                    hide-details
                    label="Ligne par page"
                    outlined></v-select>
        </div>
        <v-spacer/>
        <v-pagination v-if="total>per_page"
                      v-model="pagination.current"
                      :length="pagination.total"
                      circle
                      total-visible="6"
                      @input="onPageChange"
        ></v-pagination>
      </v-card-actions>

    </div>
  </div>
</template>

<script>

import SPFilterComponent from "@/views/campaigns/components/SPFilterComponent.vue";
import SPExcelFilterComponent from "@/views/campaigns/components/SPExcelFilterComponent.vue";
import {HTTP} from "@/http-common";

export default {
  props: ['form'],
  components: {SPExcelFilterComponent, SPFilterComponent},
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      loading: false,

      salepointAccountIds: [],
      selected: [],
      salepoints: [],

      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },

      headers: [
        {text: 'Photo', value: 'photo'},
        {text: this.$store.state.keyword.key_26, value: 'code'},
        {text: this.$store.state.keyword.key_27, value: 'name'},
        {text: this.$store.state.keyword.key_1, value: 'type'},
        {text: this.$store.state.keyword.key_28, value: 'phone'},
        {text: 'Téléphone-APP', value: 'app_phone'},
        {text: 'Classe', value: 'class'},
        {text: 'Compte Gifty', value: 'gifty_id'},
      ],
    }
  },
  methods: {
    getSalepoints(ids) {
      this.loading = true
      this.$Progress.start()

      HTTP.get('/supervisors/salepoints?page=' + this.pagination.current, {
        params: {
          per_page: this.per_page,
          filter_salepoint_ids: this.filter_salepoint_ids
        }
      }).then((res) => {
        this.loading = false
        this.$Progress.finish()

        this.salepoints = res.data.data.data
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        /***
         * Check selected
         */

        if (ids) {

          this.salepointAccountIds = ids
          this.selected = this.salepoints.filter(function (item) {
            return ids.includes(item.salepoint_account_id)
          });

        } else {
          let _vm = this
          this.selected = this.salepoints.filter(function (item) {
            return _vm.salepointAccountIds.includes(item.salepoint_account_id)
          });
        }

      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },

    onPageChange() {
      this.getSalepoints(this.salepoint_ids);
    },

    selectRow(slot) {
      let id = slot.item.salepoint_account_id
      if (!this.salepointAccountIds.includes(id)) {
        this.salepointAccountIds.push(id)
      } else {
        const index = this.salepointAccountIds.indexOf(id)
        this.salepointAccountIds.splice(index, 1)
      }

      this.form.salepoint_account_ids = this.salepointAccountIds
    },

    selectAllRow(slot) {
      let array = slot.items

      if (slot.value) {
        array.forEach(item => {
          this.salepointAccountIds.push(item.salepoint_account_id)
        })
        this.salepointAccountIds = [...new Set(this.salepointAccountIds)]
      } else {
        array.forEach(item => {
          const index = this.salepointAccountIds.indexOf(item.salepoint_account_id)
          this.salepointAccountIds.splice(index, 1)
        })
      }

      this.form.salepoint_account_ids = this.salepointAccountIds
    },

  },
  watch: {
    per_page() {
      this.pagination.current = 1
      this.getSalepoints();
    }
  }
}
</script>

<style scoped>

</style>