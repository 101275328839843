<template>
  <div>

    <v-text-field
        v-model="keyword"
        class="rounded"
        clearable
        dense
        filled
        placeholder="Recherche..."
        prepend-inner-icon="mdi-magnify"
        rounded/>

    <div>

      <div
          class="d-flex justify-space-between align-center align-content-center pa-4 mb-1 font-weight-bold bg-blue-lighten">
        <span class="blue--text">Total Grossistes : {{ total }}</span>
        <v-spacer/>
        <span class="blue--text">
         <v-icon class="mt--4">mdi-check-circle-outline</v-icon>
         Total Grossistes Sélectionnés : {{ form.wholesaler_ids.length }}
       </span>
      </div>

      <v-card-actions>
        <div class="d-flex align-center">
          <v-select v-model="per_page"
                    :items="[10,20,50,100,200,300,400,500]"
                    :style="{width: '120px'}"
                    dense
                    hide-details
                    label="Ligne par page"
                    outlined></v-select>

          <v-btn class="ml-2" depressed
                 @click="[pagination.current = 1 ,getWholesalers()]">
            <v-icon left>mdi-table-refresh</v-icon>
            Réinitialiser
          </v-btn>
        </div>
        <v-spacer/>
        <v-pagination v-if="total>per_page"
                      v-model="pagination.current"
                      :length="pagination.total"
                      circle
                      total-visible="6"
                      @input="onPageChange"
        ></v-pagination>
      </v-card-actions>

      <v-data-table v-model="selected"
                    :headers="headers"
                    :items="wholesalers"
                    :loading="loading"
                    checkbox-color="success"
                    class="table-border"
                    disable-pagination
                    hide-default-footer
                    item-key="id"
                    show-select
                    @item-selected="selectRow"
                    @toggle-select-all="selectAllRow">

        <template v-slot:item.photo="{ item }">
          <v-avatar size="38">
            <v-img v-if="item.photo"
                   :src="FILE_URL + item.photo"></v-img>
            <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
          </v-avatar>
        </template>

        <template v-slot:item.code="{ item }">
          <span class="font-weight-medium blue--text">
            {{ item.code }}
          </span>
        </template>


        <template v-slot:item.gifty_account="{ item }">
          <v-icon v-if="item.gifty_account"
                  color="success">
            mdi-check-circle
          </v-icon>
          <v-icon v-else color="red">mdi-close-circle</v-icon>
        </template>


        <template v-slot:item.tip_account="{ item }">
          <v-icon v-if="item.tip_account"
                  color="success">
            mdi-check-circle
          </v-icon>
          <v-icon v-else color="red">mdi-close-circle</v-icon>
        </template>

      </v-data-table>

      <v-card-actions>
        <div>
          <v-select v-model="per_page"
                    :items="[10,20,50,100,200,300,400,500]"
                    :style="{width: '120px'}"
                    dense
                    hide-details
                    label="Ligne par page"
                    outlined></v-select>
        </div>
        <v-spacer/>
        <v-pagination v-if="total>per_page"
                      v-model="pagination.current"
                      :length="pagination.total"
                      circle
                      total-visible="6"
                      @input="onPageChange"
        ></v-pagination>
      </v-card-actions>

    </div>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
  props: ['form'],
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      loading: false,

      keyword: null,
      selected: [],
      wholesaler_ids: [],
      wholesalers: [],

      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },

      headers: [
        {text: 'Photo', value: 'photo'},
        {text: this.$store.state.keyword.key_26, value: 'code'},
        {text: this.$store.state.keyword.key_27, value: 'name'},
        {text: this.$store.state.keyword.key_1, value: 'type'},
        {text: this.$store.state.keyword.key_28, value: 'phone'},
        {text: 'Compte Gifty', value: 'gifty_account'},
        {text: 'Compte TIP', value: 'tip_account'},
      ],
    }
  },
  methods: {
    getWholesalers(ids) {
      this.loading = true
      this.$Progress.start()

      HTTP.get('/supervisors/wholesalers?page=' + this.pagination.current, {
        params: {
          per_page: this.per_page,
          keyword: this.keyword
        }
      }).then((res) => {
        this.loading = false
        this.$Progress.finish()

        this.wholesalers = res.data.data.data
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        /***
         * Check selected
         */
        if (ids) {

          this.wholesaler_ids = ids
          this.selected = this.wholesalers.filter(function (item) {
            return ids.includes(item.id)
          });

        } else {
          let _vm = this
          this.selected = this.wholesalers.filter(function (item) {
            return _vm.wholesaler_ids.includes(item.id)
          });
        }

      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },

    onPageChange() {
      this.getWholesalers(this.wholesaler_ids);
    },

    selectRow(slot) {
      let id = slot.item.id
      if (!this.wholesaler_ids.includes(id)) {
        this.wholesaler_ids.push(id)
      } else {
        const index = this.wholesaler_ids.indexOf(id)
        this.wholesaler_ids.splice(index, 1)
      }

      this.form.wholesaler_ids = this.wholesaler_ids
    },

    selectAllRow(slot) {
      let array = slot.items
      if (slot.value) {
        array.forEach(item => {
          this.wholesaler_ids.push(item.id)
        })
      } else {
        array.forEach(item => {
          const index = this.wholesaler_ids.indexOf(item.id)
          this.wholesaler_ids.splice(index, 1)
        })
      }

      this.form.wholesaler_ids = this.wholesaler_ids
    },

  },
  watch: {
    per_page() {
      this.pagination.current = 1
      this.getWholesalers();
    },
    keyword() {
      this.pagination.current = 1
      this.getWholesalers();
    },
  }
}
</script>

<style scoped>

</style>