<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay"
                 opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-row align="center" justify="center">
        <v-col></v-col>
        <v-col class="text-right">
          <v-tooltip color="primary" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2"
                     icon
                     v-bind="attrs"
                     @click="getSupervisors()"
                     v-on="on">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Actualiser</span>
          </v-tooltip>
          <v-btn class="rounded-lg" color="primary" depressed
                 @click="addItem">
            <v-icon left>mdi-plus</v-icon>
            Ajouter un compte ASM
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="loading && supervisors.length === 0">
        <v-col v-for="(item,i) in 8" :key="i" cols="12" lg="3">
          <v-card class="shadow rounded-lg">
            <v-skeleton-loader type="list-item-avatar,article,actions"/>
          </v-card>
        </v-col>
      </v-row>


      <v-row v-if="!loading && supervisors.length === 0">
        <v-col cols="12">
          <v-card class="transparent" flat>
            <v-card-text class="text-center">
              <NoResult/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!loading && supervisors.length > 0">
        <v-col v-for="(supervisor ,i) in supervisors" :key="i" cols="12" lg="3">
          <v-card class="shadow rounded-lg">
            <v-card-text>
              <div class="text-center">
                <v-avatar size="60">
                  <v-img v-if="supervisor.photo" :src="FILE_URL + supervisor.photo"></v-img>
                  <v-img v-else :src="require('@/assets/avatar.png')"></v-img>
                </v-avatar>

                <h3 class="mt-3 mb-1">
                  {{ supervisor.name }}
                </h3>

                <h4 class="mb-1">
                  {{ supervisor.phone }}
                </h4>

                <p>{{ supervisor.email }}</p>

                <v-chip color="blue"
                        dark
                        label
                        @click="$refs.affectedSalepointsDialog.open(supervisor.id)">
                  {{ supervisor.salepoint_accounts_count }} Pos affectés
                </v-chip>

                <div class="d-block mt-2">
                  <v-chip label
                          style="background: rgba(61,178,255,0.2)"
                          text-color="blue"
                          @click="$refs.AffectedWholesalersDialog.open(supervisor.id)">
                    {{ supervisor.wholesalers_count }} Grossistes affectés
                  </v-chip>
                </div>


              </div>
            </v-card-text>

            <v-divider/>

            <v-row align="center" class="pl-2 pr-2" justify="center" no-gutters>
              <v-col cols="4">
                <v-switch
                    v-model="supervisor.is_active"
                    color="blue" readonly @click="changeStatus(supervisor.id, supervisor.is_active)"/>
              </v-col>
              <v-col class="text-right" cols="8">
                <v-tooltip color="primary" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" icon v-bind="attrs" @click="deleteItem(supervisor.id)" v-on="on">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
                <v-tooltip color="primary" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" icon v-bind="attrs" @click="editItem(supervisor)" v-on="on">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Modifier</span>
                </v-tooltip>
              </v-col>
              <v-spacer/>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <SupervisorForm ref="supervisorForm" @refresh="getSupervisors"/>
    <AffectedSalepointsDialog ref="affectedSalepointsDialog"/>
    <AffectedWholesalersDialog ref="AffectedWholesalersDialog"/>

  </div>
</template>

<script>

import SupervisorForm from "./components/SupervisorForm.vue";
import AffectedSalepointsDialog from "./components/AffectedSalepointsDialog.vue";
import AffectedWholesalersDialog from "./components/AffectedWholesalersDialog.vue";
import {HTTP} from "@/http-common";
import NoResult from "@/components/NoResult.vue";

export default {
  components: {
    AffectedSalepointsDialog,
    AffectedWholesalersDialog,
    NoResult,
    SupervisorForm,
  },
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      overlay: false,
      loading: false,
      id: '',
      supervisors: [],
    }
  },
  methods: {
    addItem() {
      this.$refs.supervisorForm.open()
    },
    editItem(item) {
      this.$refs.supervisorForm.open(item)
    },
    deleteItem(id) {
      this.id = id
      this.$confirm_dialog = true
    },
    getSupervisors() {
      this.supervisors = []
      this.loading = true
      this.$Progress.start()
      HTTP.get('/supervisors').then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.supervisors = res.data.data
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    changeStatus(id, is_active) {
      this.$Progress.start()
      this.overlay = true
      let data = {
        id: id,
        is_active: !is_active,
      }
      HTTP.post('/supervisors/change-status', data).then(() => {
        const i = this.supervisors.findIndex(item => item.id === id)
        this.supervisors[i].is_active = !is_active
        this.overlay = false
        this.$Progress.finish()
        this.$successMessage = 'Status changé avec succes !'
      }).catch(err => {
        console.log(err)
        this.$Progress.fail()
        this.overlay = false
      })
    },
  },
  created() {
    this.getSupervisors()
  },
  watch: {
    '$is_confirm': function (el) {
      if (el) {
        this.$is_confirm = false
        this.overlay = true
        this.$Progress.start()
        HTTP.delete('/supervisors/delete/' + this.id).then(() => {
          let i = this.supervisors.findIndex(item => item.id === this.id)
          this.$delete(this.supervisors, i)
          this.$successMessage = 'Cet superviseur a été supprimé avec succès'
          this.overlay = false
          this.$Progress.finish()
        }).catch(err => {
          this.overlay = false
          this.$Progress.fail()
          console.log(err)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>