<template>
  <div>
    <v-dialog v-model="dialog" persistent
              scrollable
              width="1100">
      <v-card :loading="loading">
        <v-card-title>
          Liste des points de vente affectés
          <v-spacer/>
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-4">

          <v-card-title>

            <div>
              <v-text-field v-model="keyword"
                            append-icon="mdi-magnify"
                            class="w-300"
                            clearable
                            dense
                            filled
                            hide-details
                            placeholder="Recherche..."
                            rounded single-line></v-text-field>
            </div>

            <v-spacer/>

            <v-btn class="text-none" disabled text>
              {{ total }} Élément(s)
            </v-btn>

          </v-card-title>

          <v-skeleton-loader v-if="loading" type="table"/>

          <div v-if="!loading && salepoints.length > 0">

            <v-simple-table class="table-border">
              <template v-slot:default>
                <thead>
                <tr>

                  <th class="text-left">Photo</th>

                  <th id="key_26" class="text-left">{{ keywords.key_26 }}</th>

                  <th id="key_27" class="text-left">{{ keywords.key_27 }}</th>

                  <th id="key_1" class="text-left">{{ keywords.key_1 }}</th>

                  <th id="key_28" class="text-left">{{ keywords.key_28 }}</th>

                  <th class="text-left">Téléphone-APP</th>

                  <th class="text-left">
                    <span id="key_3">{{ keywords.key_3 }}</span>
                  </th>

                  <th class="text-left">
                    <span id="key_40">{{ keywords.key_40 }}</span>
                  </th>

                  <th class="text-left">
                    Compte Gifty
                  </th>

                </tr>
                </thead>
                <tbody>
                <tr v-for="item in salepoints" :key="item.id">

                  <td>
                    <v-avatar size="38">
                      <v-img v-if="item.salepoint_account && item.salepoint_account.photo"
                             :src="FILE_URL + item.salepoint_account.photo"></v-img>
                      <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
                    </v-avatar>
                  </td>

                  <td>
                    <span class="font-weight-medium blue--text">
                     {{ item.salepoint_account.salepoint ? item.salepoint_account.salepoint.code : '-' }}
                    </span>
                  </td>

                  <td>
                    {{ item.salepoint_account.salepoint ? item.salepoint_account.salepoint.name : '-' }}
                  </td>

                  <td>
                    {{ item.salepoint_account.salepoint ? item.salepoint_account.salepoint.type : '-' }}
                  </td>

                  <td>
                    {{ item.salepoint_account.salepoint ? item.salepoint_account.salepoint.phone : '-' }}
                  </td>

                  <td>
                   <span v-if="item.salepoint_account && item.salepoint_account.salepoint_phone"
                         class="info--text">
                      {{ item.salepoint_account.salepoint_phone }}
                      </span>
                    <span v-else>-</span>
                  </td>

                  <td>
                    {{ item.salepoint_account.salepoint ? item.salepoint_account.salepoint.city : '-' }}
                  </td>

                  <td>
                    {{ item.salepoint_account.salepoint ? item.salepoint_account.salepoint.address || '-' : '-' }}
                  </td>


                  <td>
                    <v-icon v-if="item.salepoint_account && item.salepoint_account.gifty_id" color="success">
                      mdi-check-circle
                    </v-icon>
                    <v-icon v-else color="red">mdi-close-circle</v-icon>
                  </td>

                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-card-actions>
              <div>
                <v-select v-model="per_page"
                          :items="[10,20,50,100,200,300,400,500]"
                          :style="{width: '120px'}"
                          dense
                          hide-details
                          label="Ligne par page"
                          outlined></v-select>
              </div>
              <v-spacer/>
              <v-pagination v-if="total>per_page"
                            v-model="pagination.current"
                            :length="pagination.total"
                            circle
                            total-visible="6"
                            @input="onPageChange"
              ></v-pagination>
            </v-card-actions>

          </div>

          <NoResult
              v-if="!loading && salepoints.length === 0"
              class="text-center"/>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResult from "@/components/NoResult.vue";

export default {
  components: {NoResult},
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      keywords: this.$store.state.keyword,

      dialog: false,
      loading: false,
      supervisorId: '',

      keyword: '',
      salepoints: [],
      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },
    }
  },
  methods: {
    open(supervisorId) {
      this.dialog = true
      this.supervisorId = supervisorId
      this.getAffectedSalepoints()
    },

    close() {
      this.dialog = false
    },

    getAffectedSalepoints(current_page, per_page) {
      this.pagination.current = current_page ? current_page : this.pagination.current
      this.per_page = per_page ? per_page : this.per_page
      this.loading = true
      this.$Progress.start()
      HTTP.get('/supervisors/affected-salepoints?page=' + this.pagination.current, {
        params: {
          per_page: this.per_page,
          keyword: this.keyword,
          supervisor_id: this.supervisorId,
        }
      }).then((res) => {
        this.salepoints = res.data.data.data
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;
        this.loading = false
        this.$Progress.finish()
      }).catch(err => {
        this.loading = false
        this.$Progress.fail()
        console.log(err)
      })
    },
    onPageChange() {
      this.getAffectedSalepoints()
    },
  },
  watch: {
    per_page() {
      this.pagination.current = 1
      this.getAffectedSalepoints();
    },
    keyword() {
      this.getAffectedSalepoints();
    },
  }
}
</script>

<style scoped>

</style>